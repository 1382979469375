<template>
    <SlothBlock
        :title="$t('g.errors.something-wrong')"
        :subtitle="$t('g.errors.try-reload')"
        :type="SLOTH_TYPE.SLEEP"
    >
        <template slot="button">
            <f7-button
                preloader
                class="mini grey"
                :loading="false"
                :disabled="false"
                @click="reloadPage"
            >{{ $t('g.errors.reload') }}
            </f7-button>
        </template>
    </SlothBlock>
</template>

<script setup lang="ts">
import SlothBlock from "@target/components/messages/SlothBlock.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";

const emits = defineEmits(["btnEvent"]);

const reloadPage = () => {
    emits("btnEvent");
}
</script>