import PurchaseOperation from "@models/operations/PurchaseOperation";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import LogService from "@/services/log-service/LogService";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
import NewPaymentController from "@/views/payment-create-config/NewPaymentController";
import {f7} from "framework7-vue";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";

export default class EmptyPaymentHelper {
    private static _instance: EmptyPaymentHelper | null = null;

    public static of() {
        if (!EmptyPaymentHelper._instance) EmptyPaymentHelper._instance = new EmptyPaymentHelper();
        return EmptyPaymentHelper._instance;
    }

    public async handle(payment: PurchaseOperation) {
        const paymentError: LightPaymentPidResponseError = AppController.getInstance().paymentService.paymentError.value;
        switch (paymentError.code) {
            case 10033:
                LogService.of().log("EmptyPaymentHelper@handle", 10033);
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_WRONG_RANGE,
                    FirebaseEventBuilder.of().build()
                );
                FirebaseService.of().wrongRangeMetricSuccess.startObserve();
                // @ts-ignore
                f7.views.higher.router.navigate('/popup/main');
                break;
            case 10034:
                LogService.of().log("EmptyPaymentHelper@handle", 10034);
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_EMPTY_RANGE,
                    FirebaseEventBuilder.of().build()
                );
                FirebaseService.of().emptyRangeMetricSuccess.startObserve();
                await NewPaymentController.getInstance().init();

                if (NewPaymentController.getInstance().isCanSelectAgent.value) {
                    await AppController.getInstance().routerService.toCreatePaymentPopup(
                        () => {
                            AppController.getInstance().routerService.toViewPaymentCreateAgents(
                                NewPaymentController.getInstance().agent.value,
                                payment.amount,
                                AppController.getInstance().paymentService.paymentError.value,
                                NewPaymentController.getInstance().setAgent.bind(NewPaymentController)
                            )
                        }
                    );
                } else {
                    // @ts-ignore
                    f7.views.higher.router.navigate('/popup/main')
                }
                break;
            case 10030:
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_NO_AGENTS,
                    FirebaseEventBuilder.of().build()
                );
                break;
        }
    }
}