import store from '@target/core/store/store';
import {Ref, ref, computed, ToRef} from "vue";
import Country from "@/entities/Country";
import PaymentSystem from "@/entities/PaymentSystem";
import {autobind} from "@/decorators/autobind";
import Currency from "@/entities/Currency";
import ViewController from "@/interfaces/ViewController";
import {catchErrors} from "@/decorators/catch-errors";
import {f7, useStore} from 'framework7-vue';
// @ts-ignore
import AppController from '@target/components/App/ts/AppController';
import PaymentService, {createPaymentPayload} from "@/services/operations/payment/PaymentService";
import humps from "lodash-humps-ts";
import MainApiService from "@/services/api-service/MainApiService";
import Account from "@/entities/Account";
import DEFAULT_PAYMENT_SYSTEM from './DEFAULT_PAYMENT_SYSTEM.json';
import LogService from "@/services/log-service/LogService";
import PurchaseRepository from "@/repositories/purchase/PurchaseRepository";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {FirebaseService} from "@/services/firebase/FirebaseService";

declare const window: Window & typeof globalThis & { Pusher: any, Echo: any }

export interface CountrySmartSelect extends Country {
    selected: boolean;
}

export interface PaymentSystemSmartSelect extends PaymentSystem {
    selected: boolean;
}

export type DefaultAmount = {
    value: number;
    sign: string
}

export default class NewPaymentController implements ViewController {
    private static instance?: NewPaymentController;
    private _purchaseRepository: PurchaseRepository = new PurchaseRepository();
    private DEFAULT_AMOUNT_DOLLARS = 5;
    // readonly countries: Ref<Array<CountrySmartSelect>>;
    public agentsAmountRanges: Ref<AgentsAmountRanges | null> = ref(null);
    public isPaymentSystemsLoading: Ref<Boolean> = ref(false);
    private readonly _selectedPaymentSystem: Ref<any> = ref(null);
    amount: Ref<string>;
    public inputCurrency: Ref<string>;
    private readonly _currency: Ref<any>;
    btnContinueLoading: Ref<boolean>;
    private _paymentService: PaymentService;

    static getInstance() {
        if (typeof this.instance === 'undefined') {
            this.instance = new NewPaymentController();
        }
        return this.instance;
    }

    private constructor() {

        const payment: PurchaseOperation = AppController.getInstance().paymentService.payment.value;

        AppController.getInstance().setPaymentRequestData(null);
        this._paymentService = AppController.getInstance().paymentService;
        this._currency = ref(store.getters['account'].value.currency);
        this.inputCurrency = ref(store.getters['account'].value.currency.name);

        this.amount = ref(`${payment.amount} ${this._currency.value.abbr}`);
        this.btnContinueLoading = ref(false);

        this.updateAgentsAmountRanges();
    }

    public updateAgentsAmountRanges() {
        const paymentError: ToRef<LightPaymentPidResponseError> = AppController.getInstance().paymentService.paymentError;

        if (paymentError.value.data && paymentError.value.data.ranges) {
            this.agentsAmountRanges.value = paymentError.value.data.ranges;
        }
    }

    public reset() {
        this.agentsAmountRanges.value = null;
    }


    public calculateStartAmountValue(): number {
        const account: ToRef<Account> = useStore("account");
        const {buy} = account.value.currency.rates;
        return Math.ceil(this.DEFAULT_AMOUNT_DOLLARS * buy);
    }

    private calculateStartDefaultAmounts(): DefaultAmount[] {
        const account: ToRef<Account> = useStore("account");
        const {dSums} = account.value.currency;
        return dSums;
    }


    get currency(): Ref<any> {
        return this._currency;
    }

    get selectedPaymentSystem(): Ref<any> {
        return this._selectedPaymentSystem;
    }

    public getMinAmount(): number {
        return Math.ceil(this._selectedPaymentSystem.value.minAmount);
    }

    set setAmount(s: String) {
        // @ts-ignore
        this.amount.value = s;
    }

    private async configure() {
        this.setDefaultAmounts();
    }

    setDefaultAmounts() {
        const account = store.getters['account'].value;
        const defaultValues = account.currency.dSums;
    }

    @autobind
    async beforeIn() {
        await store.dispatch('setPayment', undefined);
    }

    @autobind
    @catchErrors
    async updateNeedsData() {
        await store.dispatch('fetchAccount', null);
        await store.dispatch('fetchPaymentSystems', null);
        await store.dispatch('fetchCurrencies', null);
    }

    @autobind
    @catchErrors
    async updateMyPayments() {
        await store.dispatch('fetchMyPayments', {refresh: true});
    }

    public validateMinmax() {
        let amount = parseFloat(this.amount.value.replace(/\s/g, ''));
        if (amount < 10) return false;

        if (!this.agentsAmountRanges.value) return true;

        const {min, max} = this.agentsAmountRanges.value;
        if (min == null || max == null) return true;
        if (min == 0 || max == 0) return true;

        return !(amount < min || amount > max);
    }

    @autobind
    async next() {
        let amount = parseFloat(this.amount.value.replace(/\s/g, ''));
        const payload: createPaymentPayload = {
            amount: amount,
            msid: this._paymentService.payment.value!.msid!
        }
        const operation = await this._purchaseRepository.patchEmptyPayment(payload);
        FirebaseService.of().wrongRangeMetricSuccess.checkAndSendAnalyticEvent();
        FirebaseService.of().emptyRangeMetricSuccess.checkAndSendAnalyticEvent();
        return operation;
    }

    selectPSystem(psystem: any) {
        this._selectedPaymentSystem.value = psystem;
    }

    destructor() {
        this.reset();
        NewPaymentController.instance = undefined;
    }
}
