// @ts-ignore
import store from "@target/core/store/store";
// @ts-ignore
import routes from "@target/core/routes";
import {ref, ToRef} from "@vue/runtime-core";
import LangService from "@/services/LangService";
import {Ref} from "vue";
import Account from "@/entities/Account";
import SocketService from "@/services/SockerService";
import AbstractBuyingService from "@/services/operations/AbstractBuyingService";
import PaymentService from "@/services/operations/payment/PaymentService";
import RouterService from "@/services/RouterService";
import LightAppController from "@/controllers/app/LightAppController";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import MainApiService from "@/services/api-service/MainApiService";
import LogService from "@/services/log-service/LogService";
import LightPaymentService from "@/targets/light/services/LightPaymentService";
import LightAppService from "@/services/app/LightAppService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentOthers from "@/services/firebase/firestore/documents/DocumentOthers";

type PaymentRequestData = { amount: number, psystem_id: number, currency_id?: number, type?: 'purchase' | 'sale' }

export default class AppController extends LightAppController {
    private static instance: AppController | null = null;
    readonly config: object;
    private _isAuth: Ref<Boolean> = ref(false);
    private _isReady: Ref<Boolean> = ref(false);
    private _paymentRequestData: ToRef<PaymentRequestData | null>;
    public socketIsConnecting: Ref<boolean> = ref(false);
    private _lightBuyingService: AbstractBuyingService | null = null;
    private _routerService: RouterService;
    protected _paymentService: LightPaymentService;

    constructor() {
        super();
        this.config = {
            name: 'light',
            theme: 'ios',
            store: store,
            routes: routes,
            iosTranslucentBars: false,
        }
        this._paymentService = new LightPaymentService();
        this._paymentRequestData = ref(null);
        this._routerService = new RouterService();

        const locale = localStorage.getItem('lang');
        if (locale) {
            LangService.getInstance().set(locale);
        }
    }

    get routerService(): RouterService {
        return this._routerService;
    }

    get lightBuyingService(): AbstractBuyingService | null {
        return this._lightBuyingService;
    }

    get paymentRequestData() {
        return this._paymentRequestData;
    }

    get isReady() {
        return this._isReady;
    }

    get paymentService(): PaymentService {
        return this._paymentService;
    }

    public async init() {
        try {
            let account: Account | null = null;
            let pusher = {
                host: import.meta.env.VITE_API_URL.replace('https://', ''),
                key: import.meta.env.VITE_PUSHER_KEY,
                authEndpoint: import.meta.env.VITE_API_URL + "/broadcasting/auth"
            };

            const url = new URL(window.location.href);
            if (this.isUrlHasError(url)) {
                this.parseUrlErrorAndSave(url);
                return;
            }

            await FirebaseService.of().init(async () => {
                const config = await this.fetchFirebaseMainConfigs();
                if (config) {
                    if (config.pusherKey) pusher.key = config.pusherKey;
                    if (config.mainApiUrl) {
                        pusher.host = config.mainApiUrl.replace('https://', '');
                        pusher.authEndpoint = config.mainApiUrl + "/broadcasting/auth"
                    }
                }

                if (FirebaseService.of().firestoreService) {
                    this._paymentService.timerService.updateStatusLiveTimerValues(
                        FirebaseService.of().firestoreService!.parser.makeMapForPurchaseOperationLiveTimers()
                    );
                }
            });

            if (this.isUrlHasPid(url)) {
                account = await this.initializeWithPid(url);
                this._isAuth.value = true;
            }

            await MainApiService.getInstance().updateUpdateHeadersWithXToken();

            if (account) {
                SocketService.getInstance().init(account.id, pusher).connect();
            }

            LogService.of().log("AppController@init", "initialized");
        } catch (e: any) {
            this.resetCredentials();
            this.token.value = null;
            LogService.of().error("AppController@init", "didn't initialized");
        } finally {
            this._isReady.value = true;
        }
    }

    private async fetchFirebaseMainConfigs() {
        try {
            const doc = FirebaseService.of().firestoreService!.getDocument(DocumentTypes.OTHERS) as DocumentOthers;
            const mainApiUrl = doc.mainApiUrl;
            const pusherKey = doc.pusherKey;
            if (mainApiUrl) MainApiService.getInstance().setConfigDomain(mainApiUrl);

            return {
                mainApiUrl,
                pusherKey
            }
        } catch (e) {
        }
    }

    protected async initializeWithPid(url: URL) {
        const lightAppService = new LightAppService();

        const pid = this.retrievePidFromUrl(url) as string;
        const aggregateData = await lightAppService.fetchDataWithPid(pid);

        // LogService.of().log("MainResponse", aggregateData);

        const {token, refreshToken, csp} = aggregateData.credentials;
        this.persistCredentials(token, refreshToken, csp);
        this.token = token;
        this._paymentService.payment = aggregateData.payment;
        this._paymentService.paymentError.value = aggregateData.error;

        // this.sendErrorMetrics();

        store.state.account = aggregateData.profile;
        return aggregateData.profile;
    }

    private sendErrorMetrics() {
        LogService.of().log("AppController@sendErrorMetrics", null);
        if (this._paymentService.paymentError.value) {
            if (this._paymentService.paymentError.value.code === 10030) {
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_NO_AGENTS,
                    FirebaseEventBuilder.of().build()
                );
            }
            if (this._paymentService.paymentError.value.code === 10033) {
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_WRONG_RANGE,
                    FirebaseEventBuilder.of().build()
                );
            }

            if (this._paymentService.paymentError.value.code === 10034) {
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_EMPTY_RANGE,
                    FirebaseEventBuilder.of().build()
                );
            }
        }
    }

    public setPaymentRequestData(payload: PaymentRequestData | null) {
        this._paymentRequestData.value = payload;
    }

    public async logout() {
        this._isAuth.value = false;
        try {
            await store.dispatch('reset');
        } catch (e) {
        }
        SocketService.getInstance().disconnect();
        this.resetCredentials();
    }

    private checkAuth(): boolean {
        if (localStorage.getItem('ltoken') !== null) {
            return true;
        }
        return false;
    }

    public static getInstance() {
        if (AppController.instance === null) {
            AppController.instance = new AppController()
        }
        return AppController.instance
    }
}
