<template>
    <SlothBlock
        :title="'Перерыв'"
        :subtitle="'В данный момент все агенты заняты. Пожалуйста, повторите попытку чуть позже'"
        :type="SLOTH_TYPE.RELAX"
    >
    </SlothBlock>
</template>

<script setup lang="ts">
import SlothBlock from "@target/components/messages/SlothBlock.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";

const emits = defineEmits(["btnEvent"]);

const reloadPage = () => {
    emits("btnEvent");
}
</script>