<template>
    <SlothBlock
        :title="'Измените сумму'"
        :type="SLOTH_TYPE.BROKEN"
    >
        <template #subtitle>
            <p class="subtitle">В данный момент допустимая сумма –<br> от <span v-format-number class="bold">{{ ranges.min }}₽</span> до
                <span v-format-number class="bold">{{ ranges.max }}₽</span>.<br>Вернитесь к пополнению, введите правильную сумму и
                попробуйте снова
            </p>
        </template>
    </SlothBlock>
</template>

<script setup lang="ts">
import SlothBlock from "@target/components/messages/SlothBlock.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import {PropType, toRef} from "vue";

const props = defineProps({
    ranges: {
        type: Object as PropType<{ min: number, max: number }>,
        required: true
    },
});
const emits = defineEmits(["btnEvent"]);
const ranges = toRef(props, "ranges");
const reloadPage = () => {
    emits("btnEvent");
}
</script>
<style class="scss" scoped>
.info-block {
    .subtitle {
        text-align: center;
    }

    .bold {
        font-weight: bold;
    }
}
</style>