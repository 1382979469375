<template>
    <div class="payment__error">
        <no-agents-error v-if="paymentError.code === 10030"/>
    </div>
</template>

<script setup lang="ts">
import PaymentStatusText from "../../atomic/PaymentStatusText.vue";
import PaymentViewRequisites from "@/views/payment/components/PaymentViewRequisites.vue";
import {PropType, toRef} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import NoAgentsError from "@/targets/light/views/auth-error/components/NoAgentsError.vue";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
//@ts-ignore
import AppController from "@target/components/App/ts/AppController";

const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    },
});

const paymentError: LightPaymentPidResponseError = AppController.getInstance().paymentService.paymentError;
const payment = toRef(props, 'payment');
</script>

<style src="../index.scss"></style>
<style lang="scss">
.payment__error {
    .info-block {
        margin-top: 56px;
    }
}
</style>